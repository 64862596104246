import React, { useEffect, useState } from "react";

const reasonsToMessage = {
  1: (
    <>
      <p>
        We noticed that our product didn't fit you—major bummer, right? Equally
        for us, because we try our best to make shopping with us a total breeze,
        from offering a virtual fitting room to size assistance. Nothing makes
        us happier than a happy customer.
      </p>
      <p>
        Rest assured that we're here to make things right. Let's find a
        worthwhile solution, here are a few choices
      </p>
    </>
  ),
  4: (
    <>
      <p>
        Thank you for reaching out to request a refund on your purchase. We
        appreciate your feedback and are sorry you did not receive the exact
        product you were expecting.
      </p>

      <p>
        We want you to know that the leather we use in our products is
        all-natural, and because of this, there may be subtle variations in the
        final product that we can't always control. Additionally, while we
        strive to provide high-quality images, we try our best to avoid
        excessive editing to truly represent the product in the most honest way
        possible.
      </p>

      <p>
        We take your concerns seriously and would love to find a way to make
        things better. Let's find a worthwhile solution, here are a few choices
      </p>
    </>
  ),
  5: (
    <>
      <p>
        Thank you for reaching out to request a refund on your purchase. We
        understand that sometimes our product might not meet your expectations.
        As a small business, we face significant challenges with refunds,
        including potential net losses, increased efforts for handling and
        restocking, and even product wastage.
      </p>
      <p>
        By choosing to exchange your product, you not only help us minimize
        these challenges but also allow us to better serve you and improve our
        offerings. Rest assured that we're here to make things right. Let's find
        a worthwhile solution, here are a few choices
      </p>
    </>
  ),
  9: (
    <>
      <p>
        We are so sorry to hear that it was defective. Thank you for bringing
        this to our attention because we genuinely want the best experience for
        our customers. We take quality control very seriously and are
        investigating what went wrong.
      </p>
      <p>
        Rest assured that we're here to make things right. Let's find a
        worthwhile solution, here are a few choices
      </p>
    </>
  ),
  10: (
    <>
      <p>
        We are sorry that the product you received didn't meet your
        expectations. Thank you for bringing this to our attention because we
        genuinely want the best experience for our customers. We take quality
        control very seriously and are investigating what went wrong.
      </p>
      <p>
        Rest assured that we're here to make things right. Let's find a
        worthwhile solution, here are a few choices
      </p>
    </>
  ),
  11: (
    <>
      <p>
        Thank you for reaching out to request a refund on your purchase. We
        understand that sometimes our delivery time might not meet your
        expectations. As a small business, we face significant challenges with
        refunds, including potential net losses, increased efforts for handling
        and restocking, and even product wastage.
      </p>
      <p>
        By choosing to exchange your product, you not only help us minimize
        these challenges but also allow us to better serve you and improve our
        offerings. Rest assured that we're here to make things right. Let's find
        a worthwhile solution, here are a few choices
      </p>
    </>
  ),
};

export default function ReturnSubmission({ region, mtmFee }) {
  const [returnInfo, setReturnInfo] = useState({ data: null, state: null });

  function showReturnSuccess(e, d) {
    if (d.success) {
      $("html, body").animate({ scrollTop: 0 }, "slow");

      $("[data-return-form]").addClass("display-none");
      $("[return-validation-error]").addClass("display-none");

      setReturnInfo({
        state: d.type === 2 ? "refund-confirm" : "success",
        data: d,
      });
    } else {
      // in jQuery return.js
      window.showReturnFailure(e, d);
    }
  }

  function handleRefundChange(option) {
    // call API to update refund option
    fetch("/returns/update_refund_option", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        swap: option.text,
        type: option.type,
        ids: returnInfo.data.ids,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setReturnInfo({ state: "success", data: returnInfo.data });
        } else {
          alert("Failed to update refund option");
        }
      });
  }

  function getRefundAmount() {
    switch (region) {
      case "au":
        return "100 AUD";
      case "uk":
        return "£55";
      default:
        return "$70";
    }
  }

  function getMTMAmount() {
    switch (region) {
      case "au":
        return "70 AUD";
      case "uk":
        return "£45";
      default:
        return "$50";
    }
  }

  useEffect(() => {
    $("#return-form").on("ajax:success", showReturnSuccess);

    return () => {
      $("#return-form").off("ajax:success", showReturnSuccess);
    };
  }, []);

  const options = [
    {
      text: `Get exchange & get ${getRefundAmount()} refund`,
      value: "exchange",
      type: 1,
    },
    {
      text: `Get made-to-measure without ${getMTMAmount()} fee & return fee reimbursement`,
      value: "mtm",
      type: 1,
    },
    {
      text: `Get full store credit & extra ${getRefundAmount()} refund`,
      value: "store_credit",
      type: 4,
    },
    { 
      text: "Get a full refund", 
      value: "full_refund",
      type: 2,
    },
  ];

  if (returnInfo.state === null) {
    return null;
  }

  if (returnInfo.state === "refund-confirm") {
    return (
      <div className="row text-center order-verification" style={{ padding: "10% 0" }}>
        <div className="col-sm-offset-2 col-sm-8 col-xs-12 mbh">
          <h3 className="mbm">We're Here to Help!</h3>
          <div className="font-20 hidden-md hidden-sm hidden-xs">
            {reasonsToMessage[returnInfo.data.reason]
              ? reasonsToMessage[returnInfo.data.reason]
              : reasonsToMessage[5]}
          </div>
          <div className="font-16 hidden-lg">
            {reasonsToMessage[returnInfo.data.reason]
              ? reasonsToMessage[returnInfo.data.reason]
              : reasonsToMessage[5]}
          </div>
        </div>
        <div className="col-sm-12 col-xs-12 mbl">
          {options.map((option, index) => (
            <a
              key={index}
              onClick={() => handleRefundChange(option)}
              className="form-btn-dark phl"
            >
              {option.text}
            </a>
          ))}
        </div>
      </div>
    );
  }

  if (returnInfo.state === "success") {
    return (
      <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 return-success">
        <div className="content-head text-center">
          <h2>RETURNS</h2>
          <h3>Your return request has been received!</h3>
        </div>
        <div className="row text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <i className="fa fa-check fa-2x" aria-hidden="true"></i>
            <br />
            <p>
              Your return number is{" "}
              <strong>{returnInfo.data.return_number}</strong>
            </p>
            <p>
              You'll recieve a confirmation email shortly on{" "}
              <span>{returnInfo.data.email}</span>
            </p>
            <p>
              In case of any question you can Contact us at :{" "}
              <a href="<%= linkt'mailto:contact@thejacketmaker.com'%>">
                contact@thejacketmaker.com
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return null;
}
