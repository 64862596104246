import React from "react";
import { useEffect, useState } from "react";
import StripePaymentRequest from "../checkout/StripePaymentRequest";
import MadeToMeasure from "./MadeToMeasure";
import ShoesMadeToMeasure from "./ShoesMadeToMeasure";
import ProductSizes from "./ProductSizes";
import { SizeType } from "./Types";
import { useSizeBay } from "./hooks/useSizeBay";
import { SizeError } from "./ProductSizes";
import { getCommonHeaders } from "../Utils";
import { FormattedMessage, IntlProvider } from "react-intl";
import useLocale from "../Locale";

const nonCustomizedProductIds = [7069];

const CustomizeProductButton = () => {
  return (
    <button
      className="customize-btn btn-customize-product btn btn-default pull-right"
      data-toggle="modal"
      data-target=".customize-product"
    >
      <FormattedMessage
        defaultMessage="Customize This product"
        id="product.cart.customize"
      />
    </button>
  );
};

const AddToCart = ({
  isPreOrder,
  isPreOrderValid,
  currency,
  total,
  country,
  onValidate,
  onSubmit,
  loading,
  isBeta = false,
}) => {
  const [trackMeasurement, setTrackMeasurement] = useState(false);

  const handleClick = () => {
    const [result, data] = onValidate();
    if (!result) {
      return;
    }

    data.trackMeasurement = trackMeasurement;

    onSubmit(data);
  };

  if (isPreOrder) {
    return (
      <button
        onClick={handleClick}
        className="cart-btn btn btn-default pull-right mbl"
        disabled={!isPreOrderValid || loading}
      >
        <span
          className="glyphicon glyphicon-shopping-cart mrm"
          aria-hidden="true"
        ></span>
        <FormattedMessage
          id="product.cart.pre-order"
          defaultMessage="Pre-Order"
        />
      </button>
    );
  }

  const cartButton = () => {
    return (
      <button
        onClick={handleClick}
        className="cart-btn btn btn-default pull-right mbl hi"
        disabled={loading}
      >
        {loading ? (
          <i className="fa fa-spinner fa-pulse fa-lg fa-fw" />
        ) : (
          <FormattedMessage
            defaultMessage="add to cart"
            id="product.cart.add"
          />
        )}
      </button>
    );
  };

  const getCartButton = () => {
    if (isBeta) {
      return (
        <>
          {cartButton()}
          <div className="floating-button-product hidden-sm hidden-lg">
            {cartButton()}
          </div>
        </>
      );
    }

    return cartButton();
  };

  const handleMeasuringClick = () => {
    setTrackMeasurement(true);
  }

useEffect(() => {
  const observer = new MutationObserver((mutationsList, observer) => {
    // Look through all mutations that just occured
    for(let mutation of mutationsList) {
      // If the addedNodes property has one or more nodes
      if(mutation.addedNodes.length) {
        const cartButton = document.getElementById("szb-chart-button");
        if (cartButton) {
          console.log("Cart button found");
          cartButton.addEventListener("click", handleMeasuringClick);
          // Once our button is found and event listener is added, we don't need the observer anymore
          observer.disconnect();
        }
      }
    }
  });

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  return () => {
    // Clean up the observer on component unmount
    observer.disconnect();
  };
}, []);

  return (
    <React.Fragment>
      {getCartButton()}

      <StripePaymentRequest
        currency={currency.toLowerCase()}
        amount={total}
        country={country}
        onValidate={onValidate}
        source="product"
      />
    </React.Fragment>
  );
};

export default function SizeAndCart(props) {
  const {
    id,
    sizeRequired,
    isEviternity = false,
    isBeta = false,
    isAccessory = false,
    sizetoolDisabled = false,
  } = props;
  const [selectedSize, setSelectedSize] = useState({
    measurements: {},
    shoeMeasurements: {},
  });
  const [selectedWidth, setSelectedWidth] = useState({
    id: 2,
    name: "Standard",
  });
  const [widths, setWidths] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState({
    isPreOrder: false,
    currency: "usd",
    total: 100,
    country: "US",
    mtmPrice: 0,
  });
  const [sizeError, setSizeError] = useState(false);
  const [mtmError, setMtmError] = useState(false);
  const [getSizeBaySize] = useSizeBay();
  const [cartLoading, setCartLoading] = useState(false);
  const [locale, messages] = useLocale();
  const showMTM = !(isEviternity || isAccessory);
  const hideCustomization =  nonCustomizedProductIds.includes(id);

  const redirectToCheckout = props.region !== "global";

  // Get sizes from the server
  useEffect(() => {
    fetch(`/api/products/${id}/sizes`)
      .then((response) => response.json())
      .then((data) => {
        setSizes(data);
        setWidths([
          { id: 1, name: "Narrow" },
          { id: 2, name: "Standard" },
          { id: 3, name: "Wide" },
        ]);
      });

    fetch(`/api/products/${id}/payment-details`)
      .then((response) => response.json())
      .then((data) => {
        setPaymentDetails(data);
      });
  }, []);

  const onSizeChange = (
    type,
    sizeId,
    measurements = {},
    shoeMeasurements = {}
  ) => {
    setSizeError(false);
    setMtmError(false);
    const newSize = {
      id: sizeId,
      type: type,
      measurements: measurements,
      shoeMeasurements: shoeMeasurements,
    };
    setSelectedSize(newSize);
  };

  const onEviternityWidthChange = (widthId, widthName) => {
    const newWidth = {
      id: widthId,
      name: widthName,
    };
    setSelectedWidth(newWidth);
  };

  const getData = () => {
    if (!sizeRequired) {
      return {
        product: id,
        size: 103, // Special for sizeless products
        measurements: [],
        sizeLater: false,
      };
    }
    let width = "None";
    if (selectedWidth) {
      width = selectedWidth.name;
    }
    return {
      product: id,
      size: selectedSize.id,
      sizeLater: selectedSize.type == SizeType.LATER,
      shoeMeasurements:
        selectedSize.type == SizeType.SHOES_MADE_MEASURE
          ? selectedSize.shoeMeasurements
          : [],
      measurements:
        selectedSize.type == SizeType.MADE_TO_MEASURE
          ? selectedSize.measurements
          : [],
      sizeBaySize: getSizeBaySize(),
      width: width,
    };
  };

  const getTotal = () => {
    if (
      selectedSize.type == SizeType.MADE_TO_MEASURE ||
      selectedSize.type == SizeType.SHOES_MADE_MEASURE ||
      selectedSize.type == SizeType.LATER
    ) {
      return (
        parseFloat(paymentDetails.total) + parseFloat(paymentDetails.mtmPrice)
      );
    }

    return paymentDetails.total;
  };

  const handleValidate = () => {
    setSizeError(false);
    const data = getData();
    const total = getTotal();
    if (!sizeRequired) return [true, data, total];

    switch (selectedSize.type) {
      case SizeType.LATER:
        return [true, data, total];
      case SizeType.MADE_TO_MEASURE:
        const measurementsValid =
          Object.keys(selectedSize.measurements).length >= 5 &&
          Object.values(selectedSize.measurements).every((v) => v > 0);
        console.log(
          "measurementsValid",
          measurementsValid,
          "selectedSize.measurements",
          selectedSize.measurements
        );
        setMtmError(!measurementsValid);
        return [measurementsValid, data, total];
      case SizeType.SHOES_MADE_MEASURE:
        const shoeMeasurementsValid =
          Object.keys(selectedSize.shoeMeasurements).length >= 5;
        console.log(
          "shoeMeasurementsValid",
          shoeMeasurementsValid,
          "selectedSize.shoeMeasurements",
          selectedSize.shoeMeasurements
        );
        return [shoeMeasurementsValid, data, total];
      case SizeType.REGULAR:
        const sizeValid = selectedSize.id != null;
        setSizeError(!sizeValid);
        return [sizeValid, data, total];
    }
    setSizeError(true);
    return [false, null];
  };

  const openCartDropdowns = () => {
    const desktopCart = document.getElementById("order-cart-desktop");
    const mobileCart = document.getElementById("order-cart-mobile");

    desktopCart.dispatchEvent(new Event("openCart", { bubbles: true }));
    mobileCart.dispatchEvent(new Event("openCart", { bubbles: true }));
  };

  const handleAddToCart = (data) => {
    setSizeError(false);

    setCartLoading(true);
    fetch("/order/add-product", {
      method: "POST",
      headers: getCommonHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          if (redirectToCheckout) {
            window.location.href = "/checkout";
          } else {
            openCartDropdowns();
          }
        } else {
          alert(response.message);
        }
        setCartLoading(false);
      })
      .catch((err) => {
        alert(err);
        setCartLoading(false);
      });
  };

  return (
    <IntlProvider locale={locale} messages={messages}>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        {sizeRequired && (
          <ProductSizes
            {...{ sizes, selectedSize, widths, selectedWidth }}
            currency={paymentDetails.currency}
            mtmPrice={paymentDetails.mtmPrice}
            onChange={onSizeChange}
            onEviternityWidthChange={onEviternityWidthChange}
            isEviternity={isEviternity}
            isAccessory={isAccessory}
            region={props.region}
            isBeta={isBeta}
            sizetoolDisabled={sizetoolDisabled}
            hideCustomization={hideCustomization}
          />
        )}
        <SizeError show={sizeError} />
        {sizeRequired && showMTM && !hideCustomization && (
          <MadeToMeasure
            onChange={onSizeChange}
            selectedSize={selectedSize}
            error={mtmError}
          />
        )}
        {sizeRequired && isEviternity && (
          <ShoesMadeToMeasure
            selectedSize={selectedSize}
            onChange={onSizeChange}
            error={mtmError}
          />
        )}
        <AddToCart
          {...paymentDetails}
          loading={cartLoading}
          onValidate={handleValidate}
          onSubmit={handleAddToCart}
          isBeta={isBeta}
        />
        {!hideCustomization && sizeRequired && (<CustomizeProductButton />)}
      </div>
    </IntlProvider>
  );
}
